import React from 'react';
import cl from "./task.module.scss"
import Layout from "../../components/layout/layout";
import {useTelegram} from "../../hooks/useTelegram.hook";
import {useTask} from "../../hooks/useTask";
import ContentLoader from "react-content-loader";
import TaskItem from "../../components/taskItem/taskItem";

const TaskPage = () => {

    const {id} = useTelegram()
    const {tasks, isLoadingTask, updateTask} = useTask(id)

    return (
        <Layout>
            <img className={cl.cover} src="/image/backgrounds/main.png" alt=""/>
            <div className={cl.title}>Получи бонусы за задания!</div>
            <div className={cl.body}>
                {tasks && tasks.map((task) => (
                    <TaskItem task={task} key={task.id} updateTask={updateTask}/>
                ))}
                {
                    isLoadingTask &&
                    <>
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={78}
                            viewBox="0 0 390 78"
                            backgroundColor="#2b2d3a"
                            foregroundColor="#474952"
                        >
                            <rect x="12" y="20" rx="0" ry="0" width="1" height="0"/>
                            <rect x="78" y="88" rx="0" ry="0" width="0" height="1"/>
                            <rect x="0" y="0" rx="15" ry="15" width="390" height="78"/>
                        </ContentLoader>
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={78}
                            viewBox="0 0 390 78"
                            backgroundColor="#2b2d3a"
                            foregroundColor="#474952"
                        >
                            <rect x="12" y="20" rx="0" ry="0" width="1" height="0"/>
                            <rect x="78" y="88" rx="0" ry="0" width="0" height="1"/>
                            <rect x="0" y="0" rx="15" ry="15" width="390" height="78"/>
                        </ContentLoader>
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={78}
                            viewBox="0 0 390 78"
                            backgroundColor="#2b2d3a"
                            foregroundColor="#474952"
                        >
                            <rect x="12" y="20" rx="0" ry="0" width="1" height="0"/>
                            <rect x="78" y="88" rx="0" ry="0" width="0" height="1"/>
                            <rect x="0" y="0" rx="15" ry="15" width="390" height="78"/>
                        </ContentLoader>
                    </>
                }
            </div>
        </Layout>

    );
};

export default TaskPage;