import React from 'react';

import cl from "./bonus.module.scss"
import Layout from "../../components/layout/layout";
import {useTelegram} from "../../hooks/useTelegram.hook";
import {useNavigate} from "react-router-dom";
import {useBonus} from "../../hooks/useBonus.hook";
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";
import {useQuery} from "@tanstack/react-query";
import BonusService from "../../services/bonus.service";
//import * as animationDataStars from "../../animations/stars.json";
//import Lottie from "react-lottie";

const bonusAmounts = [1000, 2000, 3000, 4000, 5000, 6000, 10000]

const BonusPage = () => {
    const {id} = useTelegram()
    const navigate = useNavigate()
    const {getBonus} = useBonus(navigate)
    const {data: bonusDay} = useQuery({
        queryKey: ["bonusInfo"],
        queryFn: () => BonusService.check(id),
    });
    const [play] = useSound(clickSound)

    // const defaultOptionsStars = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationDataStars,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice',
    //     },
    // };

    return (
        <Layout>
            <div className={cl.block}>
                <img className={cl.cover} src={"/image/faq/cover.svg"} alt={"cover"}/>
                <div className={cl.streak}>
                    {bonusAmounts.map((amount, index) =>
                        <div className={index === bonusDay-1?[cl.streakItem, cl.streakItemActive].join(" ") : [cl.streakItem,  index <= bonusDay-1 && cl.streakItemPrevActive].join(" ")} key={amount}>
                            {index !== 6 ?
                                <>

                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/image/bonus/coin.png 1x, /image/bonus/coin2x.png 2x "/>
                                        <img src="/image/bonus/coin.png" alt="coin"/>
                                    </picture>
                                    <div className={cl.streakAmount}>+{amount}</div>
                                </>
                                :
                                <div className={cl.row}>
                                    <picture>
                                        <source type="image/webp"
                                                srcSet="/image/bonus/smile.png 1x, /image/bonus/smilex2.png 2x "/>
                                        <img src="/image/bonus/smile.png" alt="smile"/>
                                    </picture>
                                    <div className={cl.column}>
                                        <img src="/image/bonus/coin.png" alt="coin"/>
                                        <div className={cl.streakAmount}>+{amount}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </div>
                <div className={cl.body}>
                    <picture>
                        <source type="image/webp" srcSet="/image/bonus/gift.png 1x, /image/bonus/giftx2.png 2x "/>
                        <img className={cl.image} src="/image/bonus/gift.png" alt="gift"/>
                    </picture>
                    <div className={cl.title}>Ваш ежедневный бонус (День {bonusDay})</div>
                    <div className={cl.subtitle}>{bonusAmounts[bonusDay-1]} NVT</div>
                    <button className={cl.btn} onClick={() => {
                        play()
                        getBonus({id})
                    }}>
                        <div className={cl.btn__content}>Получить</div>
                    </button>
                </div>

                {/*<div className={cl.stars}>*/}
                {/*    <Lottie*/}
                {/*        options={defaultOptionsStars}*/}
                {/*        height={300}*/}
                {/*        width={300}*/}
                {/*        speed={1}*/}
                {/*    />*/}
                {/*    <Lottie*/}
                {/*        options={defaultOptionsStars}*/}
                {/*        height={300}*/}
                {/*        width={300}*/}
                {/*        speed={1}*/}
                {/*    />*/}
                {/*</div>*/}

            </div>

        </Layout>
    );
};

export default BonusPage;