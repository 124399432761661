import React from 'react';

import cl from "./taskItem.module.scss"

const TaskItem = ({task, setTask, setDeletePopup, setEditPopup}) => {
    return (
        <div className={cl.block}>
            <div className={cl.column}>
                <div className={cl.title}>{task.title} {task?.complete ? <span className={cl.complete}>(Выполнено)</span> : ""}</div>
                <div className={cl.subtitle}>{task.description}</div>
            </div>
            <div className={cl.actions}>
                <button className={cl.edit} onClick={()=> {
                    setTask(task)
                    setEditPopup(true)
                }}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#8C62FE"/>
                        <path d="M8 17V14.4286L14.4286 8L17 10.5714L10.5714 17H8Z" stroke="white" strokeWidth="1.28571"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.5 9.92969L15.0714 12.5011" stroke="white" strokeWidth="1.28571"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <button className={cl.delete} onClick={()=>{ setTask(task);setDeletePopup(true);}}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#E60D5B"/>
                        <path
                            d="M17.1305 8.78773C17.2885 8.78773 17.4401 8.85052 17.5519 8.96227C17.6636 9.07403 17.7264 9.2256 17.7264 9.38364C17.7264 9.54169 17.6636 9.69326 17.5519 9.80502C17.4401 9.91677 17.2885 9.97956 17.1305 9.97956H16.5346L16.5328 10.0219L15.9768 17.811C15.9554 18.1117 15.8209 18.3931 15.6003 18.5986C15.3797 18.804 15.0894 18.9182 14.788 18.9182H9.93784C9.63639 18.9182 9.34614 18.804 9.12555 18.5986C8.90495 18.3931 8.7704 18.1117 8.749 17.811L8.19301 10.0225L8.19182 9.97956H7.59591C7.43787 9.97956 7.28629 9.91677 7.17454 9.80502C7.06278 9.69326 7 9.54169 7 9.38364C7 9.2256 7.06278 9.07403 7.17454 8.96227C7.28629 8.85052 7.43787 8.78773 7.59591 8.78773H17.1305ZM15.341 9.97956H9.38543L9.93844 17.7264H14.788L15.341 9.97956ZM13.555 7C13.7131 7 13.8646 7.06278 13.9764 7.17454C14.0882 7.28629 14.1509 7.43787 14.1509 7.59591C14.1509 7.75396 14.0882 7.90553 13.9764 8.01728C13.8646 8.12904 13.7131 8.19182 13.555 8.19182H11.1714C11.0133 8.19182 10.8618 8.12904 10.75 8.01728C10.6383 7.90553 10.5755 7.75396 10.5755 7.59591C10.5755 7.43787 10.6383 7.28629 10.75 7.17454C10.8618 7.06278 11.0133 7 11.1714 7H13.555Z"
                            fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default TaskItem;