import {useEffect, useState} from 'react';
import AdminLayout from "../../components/adminLayout/adminLayout";

import cl from "./adminTaskPage.module.scss"
import TaskItem from "../../components/admin/taskitem/taskItem";
import TaskPopup from "../../components/admin/taskPopup/taskPopup";
import TaskDeletePopup from "../../components/admin/taskDeletePopup/taskDeletePopup";
import {useTask} from "../../hooks/useTask";
import {useQuery} from "@tanstack/react-query";
import TranslateService from "../../services/translate.service";
import {useRuleWithdrawalUpdate} from "../../hooks/useRuleWithdrawalUpdate";
import {useWithdrawalUpdateMinAmount} from "../../hooks/useWithdrawalUpdateMinAmount.hook";
import {useLink} from "../../hooks/useLink.hook";
import PopupCreateLink from "../../components/popupCreateLink/popupCreateLink";
import PopupUpdateLink from "../../components/popupUpdateLink/popupUpdateLink";


const AdminTaskPage = () => {

    const [deletePopup, setDeletePopup] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [editLinkPopup, setEditLinkPopup] = useState(false);
    const [createPopup, setCreatePopup] = useState(false);
    const [checked, setChecked] = useState(false);
    const [minAmount, setMinAmount] = useState();
    const {links} = useLink()
    const [currentLink, setCurrentLink] = useState([])
    const {data: rule} = useQuery({
        queryKey: ["rule"],
        queryFn: () => TranslateService.getRule(),
    });

    useEffect(() => {
        setMinAmount(parseInt(rule?.minAmountWithdrawal))
        setChecked(Boolean(parseInt(rule?.withdrawal)))
    }, [rule]);

    const [task, setTask] = useState({});
    const {withdrawalUpdateRule} = useRuleWithdrawalUpdate()
    const {withdrawalUpdateMinAmount} = useWithdrawalUpdateMinAmount()
    const {tasks} = useTask(-1)
    const changeHandler = (e) =>{
        withdrawalUpdateRule(e)
        setChecked(e);
    }

    const send = () =>{
        withdrawalUpdateMinAmount(minAmount)
    }

    return (
        <AdminLayout>
            <TaskPopup visible={editPopup} setVisible={setEditPopup} task={task}/>
            <PopupCreateLink visible={createPopup} setVisible={setCreatePopup}/>
            <PopupUpdateLink visible={editLinkPopup} setVisible={setEditLinkPopup} data={currentLink}/>
            <TaskDeletePopup visible={deletePopup} setVisible={setDeletePopup} id={task?.id}/>
            <div className={cl.block}>
                <div className={cl.title}>Задания</div>
                <div className={cl.addTask} onClick={() => {
                    setEditPopup(true);
                    setTask({title: "", description: "", amount: "", type: "invite", maxComplete: ""})
                }}>
                    <div className={cl.addTask__title}>Добавить задание</div>
                    <button className={cl.addTask__btn}>
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17" cy="17" r="17" fill="#0D4DE6"/>
                            <path
                                d="M17 8C16.4477 8 16 8.44771 16 9V16H9C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H16V25C16 25.5523 16.4477 26 17 26C17.5523 26 18 25.5523 18 25V18H25C25.5523 18 26 17.5523 26 17C26 16.4477 25.5523 16 25 16H18V9C18 8.44771 17.5523 8 17 8Z"
                                fill="white"/>
                        </svg>
                    </button>
                </div>
                {tasks?.length > 0 &&
                    <div className={cl.tasks}>
                        {tasks && tasks.map(task =>
                            <TaskItem task={task} key={task.id} setDeletePopup={setDeletePopup} setTask={setTask}
                                      setEditPopup={setEditPopup}/>
                        )}
                    </div>
                }
                <div className={cl.title}>Вывод</div>
                <div className={cl.withdrawal}>
                    <div className={cl.withdrawalRow}>
                        <div className={cl.withdrawalTitle}>Вывод средств</div>
                        <div className={cl.withdrawalTumb}>
                            <label className={cl.switch} onClick={e => e.stopPropagation()}>
                                <input onChange={e => changeHandler(e.target.checked)} type="checkbox"
                                       checked={checked}/>

                                <div className={[cl.slider, cl.round].join(" ")}></div>
                                <div
                                    className={checked ? cl.switchLeft : cl.switchRight}>{checked ? "Вкл" : "Выкл"}</div>
                            </label>
                        </div>
                    </div>
                    <div className={cl.withdrawalSubTitle}>Минимальная сумма вывода</div>
                    <div className={cl.withdrawalInput}>
                        <input type="number" value={minAmount} onChange={(e) => setMinAmount(e.target.value)}/>
                        <button className={cl.withdrawalBtn} onClick={send}>
                            <svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3L7 9L15 1" stroke="white" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>

                </div>
                <div className={cl.title}>Ccылки</div>
                <div>
                    <div className={cl.addLink} onClick={()=>setCreatePopup(true)}>
                        <div className={cl.addLink__title}>Добавить ссылку</div>
                        <button className={cl.addLink__btn}>
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="17" cy="17" r="17" fill="#0D4DE6"/>
                                <path
                                    d="M17 8C16.4477 8 16 8.44771 16 9V16H9C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H16V25C16 25.5523 16.4477 26 17 26C17.5523 26 18 25.5523 18 25V18H25C25.5523 18 26 17.5523 26 17C26 16.4477 25.5523 16 25 16H18V9C18 8.44771 17.5523 8 17 8Z"
                                    fill="white"/>
                            </svg>
                        </button>
                    </div>
                    <div className={cl.links}>
                        {links && links.map(link =>
                            <div className={cl.link} onClick={()=>{
                                setCurrentLink(link)
                                setEditLinkPopup(true)
                            }}>
                                <div>
                                    <div className={cl.link__title}>{link.title}</div>
                                    <div className={cl.link__url}>https://t.me/nvuttibot?start=ad{link.id}</div>
                                </div>
                                <div>
                                    <div className={cl.link__counter}>{link.counter}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default AdminTaskPage;